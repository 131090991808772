// extracted by mini-css-extract-plugin
export var articleWrapper = "n_p5";
export var articleText = "n_p6 d_ds d_cq";
export var header = "n_p7 d_X d_t";
export var headerImageWrapper = "n_p8 d_bb d_Y d_6 d_5 d_2 d_7 d_bh d_bP";
export var headerGradient = "n_p9 d_bb d_Y d_6 d_5 d_2 d_7";
export var headerGradientOverlay = "n_qb d_bb d_Y d_6 d_5 d_2 d_7";
export var headerContentWrapper = "n_qc d_bx d_bM d_bB d_bG d_bj d_X";
export var headerContentWrapperAlt = "n_qd d_bx d_bM d_bB d_bG d_bj d_X";
export var contentWrapper = "n_mS d_t";
export var dateTag = "n_qf d_cc d_cp d_dn d_t";
export var icon1 = "n_qg d_cM";
export var icon2 = "n_qh d_cM d_cG";
export var tagBtn = "n_qj d_bZ d_cB d_dm d_dy";
export var headerText = "n_qk d_t d_cc d_cq d_bT";
export var center = "n_ql d_dt";
export var videoIframeStyle = "n_qm d_d3 d_t d_F d_bw d_bZ d_P";
export var articleImageWrapper = "n_qn d_cq d_X";
export var articleImageWrapperIcon = "n_qp d_cq d_X";
export var articleRow = "n_qq d_bB d_bc";
export var quoteWrapper = "n_gM d_bz d_bM d_cq";
export var quoteBar = "n_qr d_F";
export var quoteText = "n_qs";
export var authorBox = "n_qt d_t";
export var authorRow = "n_qv d_bB d_bT d_cw";
export var separator = "n_qw d_t";
export var line = "n_fh d_t d_fh d_cW d_cw";
export var authorImage = "n_ll d_b5 d_X d_t d_cr";
export var authorText = "n_qx d_cf d_cr";
export var masonryImageWrapper = "n_qy";
export var bottomSeparator = "n_qz d_t d_cw";
export var linksWrapper = "n_qB d_cf d_ds";
export var comments = "n_qC d_bB d_cw";
export var sharing = "n_qD d_bx d_bB d_bG";
export var shareText = "n_qF d_t d_dt";
export var icon = "n_qG";
export var text = "n_qH";
export var SubtitleSmall = "n_qJ";
export var SubtitleNormal = "n_qK";
export var SubtitleLarge = "n_qL";