import React from 'react';
import HTMLParser from 'html-react-parser';
import Masonry from 'react-masonry-css';

import Image from '../../../../Image';
import Lightbox from '../../../../Lightbox';
import imageHelper from '../../../../Image/helper';

import * as styles from './styles.module.css';

class GalleryLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    const align = props.section.styles.align !== undefined ? props.section.styles.align : 'Left';
    const full = props.section.styles.full === true ? 'Full' : '';
    const layout = props.section.styles.layout ? props.section.styles.layout : 'gutters';
    const images = [];
    props.section.data.forEach(
      (element) => element.type === 'IMAGES/IMAGE' && element.active === true && images.push(element),
    );

    let columns;
    if (props.section && props.section.styles && props.section.styles.columns)
      Number(({ columns } = props.section.styles));
    else columns = 4;

    const def = columns || 4;
    const md = columns === 1 ? 1 : 2;
    const breakpointColumnsObj = {
      default: def,
      768: md,
      576: 1,
    };

    this.state = {
      section: props.section,
      images,
      layout,
      align,
      full,
      breakpointColumnsObj,
      photoIndex: 0,
      isOpen: false,
      columns,
    };

    this.handleImageClick = this.handleImageClick.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleMoveNextRequest = this.handleMoveNextRequest.bind(this);
    this.handleMovePrevRequest = this.handleMovePrevRequest.bind(this);
  }

  handleImageClick(event) {
    this.setState({
      photoIndex: event.target.dataset.index,
      isOpen: true,
    });
  }

  handleCloseClick() {
    this.setState({
      isOpen: false,
    });
  }

  handleMoveNextRequest() {
    const photoIndex = parseInt(this.state.photoIndex, 10);

    this.setState({
      photoIndex: (photoIndex + 1) % this.state.images.length,
    });
  }

  handleMovePrevRequest() {
    const photoIndex = parseInt(this.state.photoIndex, 10);

    this.setState({
      photoIndex: (photoIndex + (this.state.images.length - 1)) % this.state.images.length,
    });
  }

  render() {
    const photoIndex = parseInt(this.state.photoIndex, 10);
    const textLayout = `galleryText${this.state.layout.charAt(0).toUpperCase()}${this.state.layout.slice(1)}`;
    const alignStyle = `align${this.state.align}`;

    const content = [];
    this.state.section.data.some((elem, i) => {
      let done = true;
      if (elem.type === 'HEADINGS/HEADING-TWO' || elem.type === 'PARAGRAPH/PARAGRAPH') {
        done = false;
        if (elem.active) {
          let data;
          if (elem.type === 'HEADINGS/HEADING-TWO') data = `<h2>${elem.text}</h2>`;
          else data = `<span>${elem.text}</span>`;

          const result = (
            <div
              key={`${this.props.section._id}_layout_${i}`}
              className={`col-12 ${styles[textLayout]} ${styles[alignStyle]}`}
            >
              {HTMLParser(data)}
            </div>
          );

          content.push(result);
        }
      }

      return done;
    });

    if (!this.state.images || this.state.images.length < 1) {
      return <div className={`container ${styles[`galleryContainer${this.state.full}`]}`}>{content}</div>;
    }

    const sizes = `(max-width: 576px) 100vw, (min-width: 576px and max-width: 768px) ${
      this.state.columns === 1 ? 100 : 50
    }vw, (min-width: 768px) ${100 / this.state.columns}vw`;

    let images = [];
    this.state.images.forEach((elem, i) => {
      let styleName;
      let imageStyle;
      if (this.state.layout === 'masonry') {
        styleName = styles.galleryMasonryImageWrapper;
        imageStyle = 'galleryMasonryImage';
      } else {
        if (this.state.columns === 1) styleName = 'col-12';
        else
          styleName = `col-12 col-sm-${this.state.columns === 1 ? 12 : 6} col-md-${12 / this.state.columns} ${
            this.state.layout === 'gutters' ? styles.galleryGuttersImage : styles.galleryNoGuttersImage
          }`;
        imageStyle = 'galleryImage';
      }

      let imgDesc;
      if (this.state.section.styles.showText && elem.content.id) {
        const img = imageHelper.getImageById(elem.content.id, this.props.images);
        if (img && img.meta && img.meta.imgDesc) {
          imgDesc = <span className={styles[alignStyle]}>{img.meta.imgDesc}</span>;

          if (this.state.layout === 'noGutters') imgDesc = <div className={styles.descText}>{imgDesc}</div>;
          else if (this.state.layout === 'gutters') imgDesc = <div className={styles.guttersDesc}>{imgDesc}</div>;
        }
      }

      const image = (
        <Image
          id={elem.content.id}
          url={elem.content.src}
          sizes={sizes}
          alt={elem.content.alt}
          dataIndex={i}
          imageStyleName={imageStyle}
          images={this.props.images}
          onClick={this.handleImageClick}
        />
      );

      const wrapped = (
        <div className={styleName} key={`${this.state.section._id}_layout_${i}`} data-imageid={elem.content.id}>
          {this.state.layout === 'masonry' ? image : <div className={styles.galleryImageWrapper}>{image}</div>}
          {imgDesc}
        </div>
      );

      images.push(wrapped);
    });

    if (this.props.section.styles.sortOrder && this.props.section.styles.sortOrder !== 'custom')
      images = imageHelper.sortImages(images, this.props.section.styles.sortOrder === 'descending');

    return (
      <div className={`container ${styles[`galleryContainer${this.state.full}`]}`}>
        {this.state.images.length > 0 && (
          <Lightbox
            mainImageId={this.state.images[photoIndex].content.id}
            nextImageId={this.state.images[(photoIndex + 1) % this.state.images.length].content.id}
            prevImageId={
              this.state.images[(photoIndex + (this.state.images.length - 1)) % this.state.images.length].content.id
            }
            onCloseRequest={this.handleCloseClick}
            onMovePrevRequest={this.handleMovePrevRequest}
            onMoveNextRequest={this.handleMoveNextRequest}
            visible={this.state.isOpen}
            images={this.props.images}
          />
        )}
        {content}
        <div className={`row ${styles.galleryRowWrapper} ${styles[alignStyle]}`}>
          {this.state.layout !== 'masonry' && images}
          {this.state.layout === 'masonry' && (
            <Masonry
              breakpointCols={this.state.breakpointColumnsObj}
              style={{ display: 'flex' }}
              className=""
              columnClassName=""
            >
              {images}
            </Masonry>
          )}
        </div>
      </div>
    );
  }
}

export default GalleryLayout;
