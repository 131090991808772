// extracted by mini-css-extract-plugin
export var alignDiscLeft = "q_q6 d_fm d_bD d_ds";
export var alignLeft = "q_q7 d_fm d_bD d_ds";
export var alignDiscCenter = "q_q8 d_fn d_bB d_dt";
export var alignCenter = "q_bM d_fn d_bB d_dt";
export var alignDiscRight = "q_q9 d_fp d_bF d_dv";
export var alignRight = "q_rb d_fp d_bF d_dv";
export var footerContainer = "q_rc d_dT d_bT";
export var footerContainerFull = "q_rd d_dR d_bT";
export var footerHeader = "q_kb d_kb";
export var footerTextWrapper = "q_rf d_t";
export var footerDisclaimerWrapper = "q_kj d_kj d_ch";
export var badgeWrapper = "q_rg d_t d_bx d_bM d_bC d_bG";
export var footerDisclaimerRight = "q_rh undefined";
export var footerDisclaimerLeft = "q_rj undefined";
export var verticalTop = "q_rk d_bx d_bG d_bL d_bJ";
export var firstWide = "q_rl";
export var disclaimer = "q_rm d_bx d_bG";
export var socialDisclaimer = "q_rn";
export var left = "q_rp";
export var wide = "q_rq d_cw";
export var right = "q_rr d_bH";
export var line = "q_fh d_fj d_cs";
export var badgeDisclaimer = "q_rs d_bz d_bM d_bG";
export var badgeContainer = "q_rt d_bx d_bF d_bM";
export var badge = "q_rv";
export var padding = "q_rw d_c5";
export var end = "q_rx d_bF";
export var linkWrapper = "q_ry d_dy";
export var link = "q_mW d_dy";
export var colWrapper = "q_rz d_cv";
export var consent = "q_f d_f d_bz d_bM";
export var media = "q_rB d_bv d_dw";
export var itemRight = "q_rC";
export var itemLeft = "q_rD";
export var itemCenter = "q_rF";
export var exceptionWeight = "q_rG t_sn";