// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "r_jM d_jM d_cs";
export var galleryMasonryImage = "r_jL d_jL d_t d_bP d_dy";
export var alignLeft = "r_q7 d_fm d_bD d_ds";
export var alignCenter = "r_bM d_fn d_bB d_dt";
export var alignRight = "r_rb d_fp d_bF d_dv";
export var galleryContainer = "r_rH d_dT";
export var galleryContainerFull = "r_rJ d_dR";
export var galleryRowWrapper = "r_rK d_b9";
export var galleryGuttersImage = "r_jP d_jP d_H d_cs";
export var galleryNoGuttersImage = "r_jN d_jN d_H d_cB";
export var galleryTextGutters = "r_jJ d_jJ d_ct";
export var galleryTextNoGutters = "r_jK d_jK d_ct";
export var galleryTextMasonry = "r_rL d_jJ d_ct";
export var galleryImageWrapper = "r_rM d_fd d_X";
export var descText = "r_rN d_jQ d_Y d_7 d_5 d_6 d_l";
export var guttersDesc = "r_rP";