// extracted by mini-css-extract-plugin
export var lbContainerOuter = "w_tx";
export var lbContainerInner = "w_ty";
export var movingForwards = "w_tz";
export var movingForwardsOther = "w_tB";
export var movingBackwards = "w_tC";
export var movingBackwardsOther = "w_tD";
export var lbImage = "w_tF";
export var lbOtherImage = "w_tG";
export var prevButton = "w_tH";
export var nextButton = "w_tJ";
export var closing = "w_tK";
export var appear = "w_tL";