// extracted by mini-css-extract-plugin
export var campaignContainer = "p_qM d_dT";
export var campaignContainerFull = "p_qN d_dR";
export var wrapper = "p_qP d_t";
export var leftWrapper = "p_qQ d_bx d_bL";
export var rightWrapper = "p_qR";
export var subtitle = "p_qS";
export var title = "p_qT";
export var text = "p_qH d_cq";
export var inlineWrapper = "p_qV d_bz d_bD d_t";
export var btnsWrapper = "p_qW p_qV d_bz d_bD d_t d_bG";
export var priceWrapper = "p_qX p_qV d_bz d_bD d_t d_cf d_bM";
export var priceHeading = "p_qY";
export var disclaimerWrapper = "p_qZ d_cB";
export var mediaSlot = "p_q0";
export var listWrapper = "p_q1 d_bz d_bM d_t";
export var btnWrapper = "p_d0";
export var imageWrapper = "p_q2 d_fd d_X";
export var SmallSmall = "p_q3 t_q3 t_rX t_sj";
export var SmallNormal = "p_q4 t_q4 t_rX t_sk";
export var SmallLarge = "p_q5 t_q5 t_rX t_sg";